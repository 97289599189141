@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Alegreya&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');

#root {
  overflow-x: hidden;
}

.app__wrapper {
  max-width: 1200px;
  margin: 0 auto;
}


.navbar__routes {
  padding: 1em;
}

.navbar__routes {
  background-color: #fbfbfd;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: solid 1px #bdb7b7;
}

.navbar__routes ul {
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  list-style-type: none;
  text-transform: uppercase;
  margin: 0;
}

.navbar__routes img {
  width: 25px;
}

.mode__toggle:hover {
  cursor: pointer;
}

.navbar__routes a,
.navbar__routes a:hover {
  text-decoration: none;
  font-weight: 650;
  padding: 16px;
}
.navbar__routes a,
.footer__links a {
  color: #333333;
}

.navbar__routes a:hover {
}

body {
  background-color: #fbfbfd;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: rgb(228, 228, 228);
}

body::-webkit-scrollbar-thumb {
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 100px;
}

@media (max-width: 500px) {
  .navbar__routes {
    font-size: 0.8em;
  }

  .homepage__development {
    text-align: center;
  }

  .navbar__routes ul {
    justify-content: space-between;
  }

  .footer__links a,
  .footer__links a:hover {
    font-weight: 800;
    padding: 1em;
  }

  ul {
    padding: 0;
  }
}

@media (max-width: 330px) {
  .navbar__routes,
  .footer__links {
    font-size: 0.6em;
  }
}
