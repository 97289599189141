.footer__links {
  border-top: solid 1px #bdb7b7;
  background-color: #f5f5f7;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em;
}

.footer__links ul {
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  list-style-type: none;
  text-transform: uppercase;
  margin: 0;
}

.footer__links a:hover {
  color: #3183c8;
}

.footer__links a,
.footer__links a:hover,
.footer__links p {
  text-decoration: none;
  font-weight: 650;
  padding: 16px;
}


@media (max-width: 500px) {

  .footer__links {
    font-size: 0.8em;
  }


  .footer__links ul {
    justify-content: space-between;
  }

  .footer__links a,
  .footer__links a:hover {
    font-weight: 800;
    padding: 1em;
  }

  ul {
    padding: 0;
  }
}

@media (max-width: 330px) {

  .footer__links {
    font-size: 0.6em;
  }
}
