@import url(https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Alegreya&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap);
/* Styles for the Techstack component */

.tech__title {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3rem;
}

.tech__list {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.tech__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2rem;
  padding: 1rem;
  background-color: white;
  border-radius: 5px;
  border: solid 1px #bdb7b7;
  animation: slideFromLeft .6s ease-out;
}

.tech__logo {
  width: 100px;
  margin-bottom: 1rem;
}

.tech__name {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: #2368a2;
}

/* Animation keyframes */

@keyframes slideFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Mobile styles */
@media (max-width: 985px) {
  .tech__logo {
    width: 75px;
    margin-bottom: 0.2em;
  }
}

/* Mobile styles */
@media (max-width: 850px) {
  .tech__title {
    font-size: 1.5em;
  }

  .tech__item {
    margin: 0.5em;
  }

  .tech__logo {
    width: 60px;
    margin-bottom: 0.2em;
  }

  .tech__name {
    font-size: 1em;
  }

  .tech__list {
    flex-wrap: wrap;
  }
}

.homepage__header {
  display: flex;
  min-height: 30vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homepage__name {
  font-size: 4em;
}

.homepage__role {
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  color: #616161;
}

.homepage__content {
  text-align: center;
}

@media (max-width: 500px) {
  .homepage__name {
    font-size: 4em;
  }

  .homepage__header {
    padding-top: 10%;
  }
}

.portfolio {
  margin: 6em 0;
}

.portfolio__title {
  text-align: center;
}

.portfolio__experience {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 5em 5em 5em;
}

.portfolio__experience--category {
  text-align: center;
  color: #141414;
}

.portfolio__experience__card,
.portfolio__education {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: solid 1px #bdb7b7;
  padding: 16px 16px 0 16px;
  margin: 24px auto;
  text-align: center;
  max-width: 750px;
  border-radius: 10px;
}

.portfolio__experience--title,
.portfolio__wrapper--title {
  font-weight: 650;
  text-align: center;
  margin-top: 2em;
}

.cv__wrapper {
  justify-content: space-between;
}

.portfolio__wrapper {
  display: flex;
  justify-content: center;
}

.portfolio__page {
  margin-bottom: 15%;
}

.portfolio__education {
  margin-top: 2em;
  background-color: #ffffff;
  border: solid 2px #bdb7b7;
  flex-basis: 50%;
}

.portfolio__education p {
  font-size: 17px;
}

.portfolio__experience--category__wrapper {
  border-top: 1px solid rgb(0, 0, 0, 0.15);
  margin: 4px;
  padding-top: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconify {
  width: 50px;
}

.portfolio__education img {
  display: flex;
  max-width: 100%;
}

.portfolio__button {
  display: inline-block;
  padding: 10px;
  border-radius: 12px;
  font-size: 1rem;
  background-color: transparent;
  color: black;
  border: 1px solid lightgray;
  margin-bottom: 8px;
}

.portfolio__button:hover {
  border: 1px solid black;
  cursor: pointer;
}

.portfolio__experience__card--image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.portfolio__article--paragraph {
  font-size: 16px;
}

@media (max-width: 500px) {
  .portfolio__education p {
    font-size: 12px;
  }

  .portfolio__button {
    border-color: black;
  }

  .portfolio__experience {
    margin: 0;
  }

  .portfolio__experience--category {
    font-size: 20px;
  }

  .portfolio__experience--title,
  .portfolio__wrapper--title {
    font-size: 20px;
    margin: 15px 0 0 8px;
  }

  .portfolio__experience,
  .portfolio__wrapper {
    flex-direction: column;
  }

  .portfolio__education img {
    width: 300px;
  }
}

.primaryButton {
  padding: 8px;
  margin: 5px;

  background-color: transparent;
  color: black;

  border: 2px solid #bdb7b7;
}

.primaryButton:hover {
  cursor: pointer;
  border-color: black;
}

.articleLink {
  color: black;
}

.articleLink:hover {
  text-decoration: none;
  color: black;
}

.cv__wrapper {
  display: flex;
  align-items: center;
  color: white;
  margin: 16px 0 0 0;
  padding: 8px;
  border-top: 1px solid rgb(0, 0, 0, 0.15);
}

.cv__description--edu {
  color: black;
}

.cv__study {
  text-align: left;
}

.cv__view-subjects {
  text-decoration: underline;
}

.cv__wrapper p {
  color: #343a40;
  font-size: 14px;
}

.cv__date {
  color: #1a4971;
}

.cv__wrapper img {
  max-height: 75px;
  max-width: 75px;
}


.project__wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 24px auto 64px;
  grid-row-gap: 24px;
  row-gap: 24px;
  margin-top: 2em;
  max-width: 750px;
  padding: 16px;
}

.button__wrapper {
  display: flex;
  justify-content: center;
}

.project__text {
  font-size: 16px;
  text-align: center;
}

.project__topic {
  color: black;
}

.project__title {
  text-align: center;
  font-size: 1.4em;
  color: #141414;
  margin: 1em;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 800;
}

.project__image {
  object-fit: cover;
  height: 250px;
}

.link__button {
  margin-bottom: 1em;
}

.btn:hover {
  color: green;
}

.primaryButton {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 1rem;
  color: #ffffff;
  background-color: #007bff;
  border: none;
}

.primaryButton:hover {
  background-color: #0062cc;
  cursor: pointer;
}

@media (max-width: 768px) {
  .portfolio__experience--title {
    margin-top: 4em;
  }

  .project__wrapper {
    flex-direction: column;
    align-items: center;
  }

  .project__image {
    height: 300px;
  }

  .project__title {
    font-size: 20px;
  }
}

.footer__links {
  border-top: solid 1px #bdb7b7;
  background-color: #f5f5f7;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em;
}

.footer__links ul {
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  list-style-type: none;
  text-transform: uppercase;
  margin: 0;
}

.footer__links a:hover {
  color: #3183c8;
}

.footer__links a,
.footer__links a:hover,
.footer__links p {
  text-decoration: none;
  font-weight: 650;
  padding: 16px;
}


@media (max-width: 500px) {

  .footer__links {
    font-size: 0.8em;
  }


  .footer__links ul {
    justify-content: space-between;
  }

  .footer__links a,
  .footer__links a:hover {
    font-weight: 800;
    padding: 1em;
  }

  ul {
    padding: 0;
  }
}

@media (max-width: 330px) {

  .footer__links {
    font-size: 0.6em;
  }
}

#root {
  overflow-x: hidden;
}

.app__wrapper {
  max-width: 1200px;
  margin: 0 auto;
}


.navbar__routes {
  padding: 1em;
}

.navbar__routes {
  background-color: #fbfbfd;
  z-index: 999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: solid 1px #bdb7b7;
}

.navbar__routes ul {
  display: flex;
  flex-wrap: wrap;

  justify-content: center;
  list-style-type: none;
  text-transform: uppercase;
  margin: 0;
}

.navbar__routes img {
  width: 25px;
}

.mode__toggle:hover {
  cursor: pointer;
}

.navbar__routes a,
.navbar__routes a:hover {
  text-decoration: none;
  font-weight: 650;
  padding: 16px;
}
.navbar__routes a,
.footer__links a {
  color: #333333;
}

.navbar__routes a:hover {
}

body {
  background-color: #fbfbfd;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-track {
  background: rgb(228, 228, 228);
}

body::-webkit-scrollbar-thumb {
  box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
  border-radius: 100px;
}

@media (max-width: 500px) {
  .navbar__routes {
    font-size: 0.8em;
  }

  .homepage__development {
    text-align: center;
  }

  .navbar__routes ul {
    justify-content: space-between;
  }

  .footer__links a,
  .footer__links a:hover {
    font-weight: 800;
    padding: 1em;
  }

  ul {
    padding: 0;
  }
}

@media (max-width: 330px) {
  .navbar__routes,
  .footer__links {
    font-size: 0.6em;
  }
}

