.project__wrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 24px auto 64px;
  row-gap: 24px;
  margin-top: 2em;
  max-width: 750px;
  padding: 16px;
}

.button__wrapper {
  display: flex;
  justify-content: center;
}

.project__text {
  font-size: 16px;
  text-align: center;
}

.project__topic {
  color: black;
}

.project__title {
  text-align: center;
  font-size: 1.4em;
  color: #141414;
  margin: 1em;
  font-family: 'Titillium Web', sans-serif;
  font-weight: 800;
}

.project__image {
  object-fit: cover;
  height: 250px;
}

.link__button {
  margin-bottom: 1em;
}

.btn:hover {
  color: green;
}

.primaryButton {
  display: inline-block;
  padding: 10px 20px;
  border-radius: 30px;
  font-size: 1rem;
  color: #ffffff;
  background-color: #007bff;
  border: none;
}

.primaryButton:hover {
  background-color: #0062cc;
  cursor: pointer;
}

@media (max-width: 768px) {
  .portfolio__experience--title {
    margin-top: 4em;
  }

  .project__wrapper {
    flex-direction: column;
    align-items: center;
  }

  .project__image {
    height: 300px;
  }

  .project__title {
    font-size: 20px;
  }
}
