.cv__wrapper {
  display: flex;
  align-items: center;
  color: white;
  margin: 16px 0 0 0;
  padding: 8px;
  border-top: 1px solid rgb(0, 0, 0, 0.15);
}

.cv__description--edu {
  color: black;
}

.cv__study {
  text-align: left;
}

.cv__view-subjects {
  text-decoration: underline;
}

.cv__wrapper p {
  color: #343a40;
  font-size: 14px;
}

.cv__date {
  color: #1a4971;
}

.cv__wrapper img {
  max-height: 75px;
  max-width: 75px;
}

