.portfolio {
  margin: 6em 0;
}

.portfolio__title {
  text-align: center;
}

.portfolio__experience {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 5em 5em 5em;
}

.portfolio__experience--category {
  text-align: center;
  color: #141414;
}

.portfolio__experience__card,
.portfolio__education {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  border: solid 1px #bdb7b7;
  padding: 16px 16px 0 16px;
  margin: 24px auto;
  text-align: center;
  max-width: 750px;
  border-radius: 10px;
}

.portfolio__experience--title,
.portfolio__wrapper--title {
  font-weight: 650;
  text-align: center;
  margin-top: 2em;
}

.cv__wrapper {
  justify-content: space-between;
}

.portfolio__wrapper {
  display: flex;
  justify-content: center;
}

.portfolio__page {
  margin-bottom: 15%;
}

.portfolio__education {
  margin-top: 2em;
  background-color: #ffffff;
  border: solid 2px #bdb7b7;
  flex-basis: 50%;
}

.portfolio__education p {
  font-size: 17px;
}

.portfolio__experience--category__wrapper {
  border-top: 1px solid rgb(0, 0, 0, 0.15);
  margin: 4px;
  padding-top: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.iconify {
  width: 50px;
}

.portfolio__education img {
  display: flex;
  max-width: 100%;
}

.portfolio__button {
  display: inline-block;
  padding: 10px;
  border-radius: 12px;
  font-size: 1rem;
  background-color: transparent;
  color: black;
  border: 1px solid lightgray;
  margin-bottom: 8px;
}

.portfolio__button:hover {
  border: 1px solid black;
  cursor: pointer;
}

.portfolio__experience__card--image {
  max-width: 100%;
  height: auto;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15);
}

.portfolio__article--paragraph {
  font-size: 16px;
}

@media (max-width: 500px) {
  .portfolio__education p {
    font-size: 12px;
  }

  .portfolio__button {
    border-color: black;
  }

  .portfolio__experience {
    margin: 0;
  }

  .portfolio__experience--category {
    font-size: 20px;
  }

  .portfolio__experience--title,
  .portfolio__wrapper--title {
    font-size: 20px;
    margin: 15px 0 0 8px;
  }

  .portfolio__experience,
  .portfolio__wrapper {
    flex-direction: column;
  }

  .portfolio__education img {
    width: 300px;
  }
}

.primaryButton {
  padding: 8px;
  margin: 5px;

  background-color: transparent;
  color: black;

  border: 2px solid #bdb7b7;
}

.primaryButton:hover {
  cursor: pointer;
  border-color: black;
}

.articleLink {
  color: black;
}

.articleLink:hover {
  text-decoration: none;
  color: black;
}
