.homepage__header {
  display: flex;
  min-height: 30vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.homepage__name {
  font-size: 4em;
}

.homepage__role {
  text-align: center;
  font-size: 24px;
  font-weight: 400;
  color: #616161;
}

.homepage__content {
  text-align: center;
}

@media (max-width: 500px) {
  .homepage__name {
    font-size: 4em;
  }

  .homepage__header {
    padding-top: 10%;
  }
}
