/* Styles for the Techstack component */

.tech__title {
  font-size: 3rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 3rem;
}

.tech__list {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.tech__item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 2rem;
  padding: 1rem;
  background-color: white;
  border-radius: 5px;
  border: solid 1px #bdb7b7;
  animation: slideFromLeft .6s ease-out;
}

.tech__logo {
  width: 100px;
  margin-bottom: 1rem;
}

.tech__name {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  color: #2368a2;
}

/* Animation keyframes */

@keyframes slideFromLeft {
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* Mobile styles */
@media (max-width: 985px) {
  .tech__logo {
    width: 75px;
    margin-bottom: 0.2em;
  }
}

/* Mobile styles */
@media (max-width: 850px) {
  .tech__title {
    font-size: 1.5em;
  }

  .tech__item {
    margin: 0.5em;
  }

  .tech__logo {
    width: 60px;
    margin-bottom: 0.2em;
  }

  .tech__name {
    font-size: 1em;
  }

  .tech__list {
    flex-wrap: wrap;
  }
}
